



















import { Component, Vue, Inject } from 'vue-property-decorator';
import AuthService from '@/services/auth-service';

@Component
export default class Login extends Vue {
  @Inject() AuthService!: AuthService;

  public currentUser? = '';

  public isLoggedIn = false;

  get username(): string|undefined {
    return this.currentUser;
  }

  public async login() {
    const redirect = this.$route.query.redirect as string;
    await this.AuthService.login(redirect);
    this.refreshUser();
  }

  public async logout() {
    await this.AuthService.logout();
    this.refreshUser();
  }

  public async mounted() {
    await this.refreshUser();
  }

  async refreshUser() {
    const user = await this.AuthService.getUser();

    if (user) {
      this.currentUser = user.profile.name;
      this.isLoggedIn = !this.AuthService.IsExpired();
    } else {
      this.currentUser = '';
      this.isLoggedIn = false;
    }
  }
}
